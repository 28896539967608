import React, { useState, useEffect } from "react"
import { Box, Image } from "theme-ui"
import styled from "styled-components"
import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import TextContent from "../components/TextContent"
import useStories from "../hooks/useStories"
import cloudinaryString from "../helpers/cloudinaryString"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"

export default function OctoberFest({ data }) {
  const { getStoryByTag } = useStories()

  const oktoberfest1 = getStoryByTag("oktoberfest1")

  return (
    <Layout
      pageTitle="Dockside Brewery's Oktoberfest"
      pageHero={cloudinaryOptimize(
        "https://res.cloudinary.com/gonation/image/upload/v1722438296/gonation.data.prod/yutmcuzul0b3rkriemxs.webp",
        1200
      )}
    >
      <Page>
        <Box
          sx={{
            m: "auto",
            width: "100%",
            p: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bg: "secondary",
          }}
        >
          <Image
            sx={{ maxWidth: "400px", m: "auto" }}
            src={cloudinaryOptimize(
              "https://res.cloudinary.com/gonation/image/upload/v1722527656/sites/dockside-brewing/image0-6_2.jpg"
            )}
            alt="Oktober Fest"
          />
        </Box>
        <AboutContent>
          <AboutText>
            <AboutDescription>
              <TextContent
                title={oktoberfest1?.title}
                subtitle={oktoberfest1?.subtitle}
                body={oktoberfest1?.body}
              />
              <MultipleLinksSelector links={oktoberfest1?.ctaLinks} />
            </AboutDescription>
          </AboutText>

          <ImageContainer>
            {oktoberfest1?.media?.map((media, index) => {
              return (
                <AboutImage
                  src={cloudinaryString(
                    oktoberfest1.media?.[index].cloudinaryId,
                    1000
                  )}
                />
              )
            })}
          </ImageContainer>
        </AboutContent>
      </Page>
    </Layout>
  )
}

const Page = styled.section``

const AboutContent = styled.section`
  padding: 2rem 0;
  background-color: ${props =>
    props.theme.primary ? props.theme.primary : "black"};
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`
const AboutText = styled.div`
  color: white;
  padding: 1rem;
  @media (min-width: 1024px) {
    padding-right: 4rem;
    width: 50%;
    padding: 0;
    max-width: 700px;
  }
`

const AboutTitle = styled.h1`
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
`

const AboutDescription = styled.div`
  padding: 1.5rem 2rem;
  h2 {
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    margin-bottom: 1.25rem;
  }
  ul,
  li {
    list-style: disc;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    width: 50%;
    max-width: 700px;
  }
`
const AboutImage = styled.img`
  width: 100%;
`

const UnorderedList = styled.ul`
  li {
    color: rgb(247, 218, 151);
  }
`

const styles = {
  buttonContainer: {
    display: ["flex"],
    justifyContent: ["center", "", "flex-start"],
    marginTop: "3rem",
    a: {
      background: "rgb(202,54,37)",
      color: "white",
      textTransform: "uppercase",
      WebkitAlignItems: "center",
      WebkitBoxAlign: "center",
      msFlexAlign: "center",
      alignItems: "center",
      fontWeight: 500,
      padding: ["1.25rem 2.5rem", "1.5rem 3.5rem"],
      borderRadius: "4px",
      fontSize: "1rem",
      transition: "all ease-in-out 0.5s",
      ":hover": {
        border: "solid 1px",
        backgroundColor: "transparent",
      },
    },
  },
}

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
